


















































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ModalMoveToFolder extends Vue {
  @Prop() private listItem!: any[]
  @Prop() moveToFolderMess!: string
  @Prop() private displayNameKey!: string
  private selectedItem: any = {}
  private ableClose: boolean = false

  handlePreventEvent(event: any) {
    if (!this.ableClose && this.selectedItem.id !== this.listItem[0].id) {
      event.preventDefault()
      this.confirmHideModal()
    }
  }

  created() {
    this.watchListItem()
  }

  @Watch('listItem')
  watchListItem() {
    if (this.listItem.length) {
      this.selectedItem = this.listItem[0]
    } else {
      this.selectedItem[this.displayNameKey] = ''
    }
  }

  confirmHideModal() {
    if (this.selectedItem.id === this.listItem[0].id) {
      this.hideModal()
    } else {
      this.$bvModal.show('move-folder-modal-confirm')
    }
  }

  async hideModal() {
    await this.handleHide()
    this.ableClose = false
  }

  async handleHide() {
    this.ableClose = true
    this.$bvModal.hide('move-folder')
  }

  handleChange(index: number) {
    this.selectedItem = this.listItem[index]
  }

  onSubmit() {
    this.$emit('submit', this.selectedItem)
    this.hideModal()
  }
}
