










































































































































































































































































import FormatDate from '@/helpers/FormatDate'
import PreviewText from '@/helpers/PreviewText'
import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
import ModalMoveToFolder from '@/components/Modal/ModalMoveToFolder.vue'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
import { triggerFavorite } from '@/helpers/GetNotificationCount'
import {
  State,
  IdName,
  FileDetail,
  FileHistory,
  ShareFolderFileButtonState,
  EDeleteFileType,
  MoveToUnclsFrom,
  EUserStatus,
  EFileBelong
} from '@/models'

@Component({ components: { ModalMoveToFolder } })
export default class FileDetailLayout extends Vue {
  //start screen
  @Prop() private title!: string
  @Prop() private buttonState?: ShareFolderFileButtonState
  private isCommonTopic = !this.$route.params.groupId
  private roleEditFile: boolean = false
  private buttonRealState: ShareFolderFileButtonState = new ShareFolderFileButtonState()
  private state: any = State

  //data
  private currentFile: FileDetail = new FileDetail()
  private fileHistories: FileHistory[] = []
  private fileFolders: IdName[] = []
  private markAsFavor: boolean = false

  //history
  private creater: any = {}
  private lastUpdater: any = {}
  private selectedHistoryId: number = -1

  //api helper
  private page: number = 1
  private maxPage: number = 0
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists

  //modal helper
  private modalMess: string = ''
  private modalAction: any = () => {}

  //folder default
  private unclassId: number = -1
  private binId: number = -2

  created() {
    this.startScreen()
  }

  /**
   * Binding data at start
   */
  @Watch('$route.params.fileId')
  async startScreen() {
    this.handleButtonState()
    await this.getFileDetail()
    this.getAllFolder()
    this.isFileFavorite()
  }

  /**
   * Check logined user role
   */
  @Watch('$store.state.userInfo.user.group_role')
  handleRoleEditFile() {
    if (this.isCommonTopic) {
      this.roleEditFile = false
      // this.currentFile.creator.id === this.$store.state.userInfo.user.info.id
    } else {
      this.roleEditFile = this.$store.state.userInfo.user.group_role.role_edit_shared_folder
    }
  }

  /**
   * Rebind data
   */
  init() {
    this.page = 1
    this.getFileDetail()
  }

  /**
   * Call API get file detail
   */
  async getFileDetail() {
    if (this.isCommonTopic) {
      return CommonSharedFolderService.getFileCommonTopic(
        this.$route.params.fileId
      )
        .then(res => {
          if (res.status === 200) {
            this.historiesDescription(res.data)
            this.currentFile = res.data
          }
        })
        .then(() => this.getHistory())
        .catch(err => {
          this.catchFileNotFoundErr(err)
        })
        .finally(() => {
          this.handleRoleEditFile()
        })
    } else {
      return GroupSharedFolderService.getFileById(
        this.$route.params.groupId,
        this.$route.params.fileId
      )
        .then(res => {
          if (res.status === 200) {
            this.historiesDescription(res.data)
            this.currentFile = res.data
          }
        })
        .then(() => this.getHistory())
        .catch(err => {
          this.catchFileNotFoundErr(err)
        })
        .finally(() => {
          this.handleRoleEditFile()
        })
    }
  }

  catchFileNotFoundErr(err: any) {
    if (err.response.status === 404) {
      this.modalMess = this.$t('common.message.file_not_exist') as string
      this.modalAction = () => this.jumpBack()
    } else {
      this.modalMess = this.$t('common.message.uncatch_error') as string
      this.modalAction = () => {}
    }
    this.$bvModal.show('modal-error-file-detail')
  }

  /**
   * Set creator and lastest updater for histories
   */
  historiesDescription(currentFile: FileDetail) {
    //History creator
    this.creater = {
      updateDate: this.moment(currentFile.created_at).format(
        'YYYY/MM/DD HH:mm'
      ),
      updaterName: currentFile.creator?.name,
      user_active_status: currentFile.creator_active_status,
      name_company: currentFile.name_company_creater
    }

    //History lastest updater
    this.lastUpdater = {
      updateDate: this.moment(currentFile.updated_at).format(
        'YYYY/MM/DD HH:mm'
      ),
      updaterName: currentFile.updater.name,
      user_active_status: currentFile.updater_active_status,
      name_company: currentFile.name_company_updater
    }
  }

  /**
   * Add current file detail to file history
   */
  currentHistory(lastestUpdatedDate?: string) {
    return new FileHistory({
      id: -1,
      file_id: this.currentFile.id,
      version: this.currentFile.version,
      uploaded_at: this.formatHistoryUpdateDate(
        lastestUpdatedDate ? lastestUpdatedDate : this.currentFile.created_at
      ),
      name: this.currentFile.name,
      creator: this.currentFile.last_uploader,
      creater_active_status: this.currentFile.updater_active_status,
      name_company: this.currentFile.name_company_creater
    })
  }

  /**
   * Call API to get file overwrite histories
   */
  getHistory(loadMore?: boolean) {
    if (!this.currentFile.is_in_bin) {
      if (this.isCommonTopic) {
        CommonSharedFolderService.getFileCommonTopicHistory({
          file_id: this.$route.params.fileId,
          page: this.page,
          limit: this.limit
        })
          .then(res => {
            if (res.status === 200) {
              this.hanldeAfterGetHistory(res, loadMore)
            }
          })
          .catch(err => {
            this.catchFileNotFoundErr(err)
          })
      } else {
        GroupSharedFolderService.getFileHistory({
          group_id: this.$route.params.groupId,
          file_id: this.$route.params.fileId,
          page: this.page,
          limit: this.limit
        })
          .then(res => {
            if (res.status === 200) {
              this.hanldeAfterGetHistory(res, loadMore)
            }
          })
          .catch(err => {
            this.catchFileNotFoundErr(err)
          })
      }
    }
  }

  /**
   * Handle after get history
   */
  hanldeAfterGetHistory(res: any, loadMore?: boolean) {
    let convertData: FileHistory[] = []
    if (loadMore) {
      //handle load more
      convertData = res.data.data.map((item: FileHistory) => {
        item.uploaded_at = this.formatHistoryUpdateDate(item.uploaded_at)
        return item
      })
      this.fileHistories = [...this.fileHistories, ...convertData]
    } else {
      //hanle first time call API
      convertData = res.data.data.map((item: FileHistory) => {
        item.uploaded_at = this.formatHistoryUpdateDate(item.uploaded_at)
        return item
      })
      this.fileHistories = [
        this.currentHistory(
          convertData.length ? convertData[0].updated_at : undefined
        ),
        ...convertData
      ]
    }
    //set max page
    this.maxPage = res.data.last_page
  }

  /**
   * Handle load more
   */
  loadMore() {
    setTimeout(() => {
      this.page++
      this.getHistory(true)
    }, 100)
  }

  /**
   * Call API get all folder
   */
  getAllFolder() {
    if (!this.isCommonTopic) {
      if (this.currentFile.belong_to === EFileBelong.TOPIC) {
        this.fileFolders = [
          {
            id: this.binId,
            name: `${this.$t('groups.bin')}`,
            nickname: '',
            name_company: '',
            user_active_status: 0
          }
        ]
        return
      }
      GroupSharedFolderService.getAllFolderOfSharedFolder(
        this.$route.params.groupId
      )
        .then(res => {
          if (res.status === 200) {
            const defaultFolder = [
              {
                id: this.unclassId,
                name: `${this.$t('groups.unclassified')}`
              },
              {
                id: this.binId,
                name: `${this.$t('groups.bin')}`
              }
            ]
            this.fileFolders = [...defaultFolder, ...res.data]
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.modalAction = () => {}
          this.$bvModal.show('modal-error-file-detail')
        })
    }
  }

  /**
   * Call API check that file is favorite
   */
  isFileFavorite() {
    if (!this.currentFile.is_in_bin) {
      if (this.isCommonTopic) {
        CommonSharedFolderService.isCommonFileFavorite(
          this.$route.params.fileId
        )
          .then(res => {
            if (res.status === 200) {
              this.markAsFavor = res.data.is_favorite
            }
          })
          .catch(err => {
            this.catchFileNotFoundErr(err)
          })
      } else {
        GroupSharedFolderService.isFileFavorite(
          this.$route.params.groupId,
          this.$route.params.fileId
        )
          .then(res => {
            if (res.status === 200) {
              this.markAsFavor = res.data.is_favorite
            }
          })
          .catch(err => {
            this.catchFileNotFoundErr(err)
          })
      }
    }
  }

  handleButtonState() {
    if (this.buttonState) {
      this.buttonRealState = this.buttonState
    }
  }

  handleFavor() {
    if (this.isCommonTopic) {
      CommonSharedFolderService.markCommonFileAsFavorite(
        this.$route.params.fileId
      )
        .then(res => {
          if (res.status === 200) {
            this.markAsFavor = res.data.is_favorite
            triggerFavorite()
          }
        })
        .catch(err => {
          this.catchFileNotFoundErr(err)
        })
    } else {
      GroupSharedFolderService.markFileAsFavorite(
        this.$route.params.groupId,
        this.$route.params.fileId
      )
        .then(res => {
          if (res.status === 200) {
            this.markAsFavor = res.data.is_favorite
            triggerFavorite()
          }
        })
        .catch(err => {
          this.catchFileNotFoundErr(err)
        })
    }
  }

  handleMoveToFolder(selectedFolder: IdName) {
    if (selectedFolder.id === this.unclassId) {
      this.handleMoveToUnclassified(selectedFolder)
      return
    }
    if (selectedFolder.id === this.binId) {
      this.handleMoveToBin()
      return
    }
    this.$emit('handleMoveToFolder', selectedFolder)
  }

  handleMoveToBin() {
    if (this.isCommonTopic) {
      this.deleteFile()
    } else {
      this.moveGroupFileToBin()
    }
  }

  /**
   * Handle move file from  selected folder to unclassified
   * Call API move to selected folder
   */
  handleMoveToUnclassified(selectedFolder: IdName) {
    this.$blockui.show()
    GroupSharedFolderService.moveFilesToUnclassified(
      this.$route.params.groupId,
      [this.currentFile.id],
      MoveToUnclsFrom.FOLDER
    )
      .then(res => {
        if (res.status === 200) {
          this.$emit('changeFolderName', selectedFolder)
        }
      })
      .catch(err => {
        this.catchFileNotFoundErr(err)
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API delete common file
   */
  deleteFile() {
    this.$blockui.show()
    CommonSharedFolderService.deleteCommonFile({
      file_ids: [this.currentFile.id],
      delete_type: EDeleteFileType.HARD_DELETE
    })
      .then(res => {
        if (res.status === 200) {
          this.modalMess = this.$t('common.message.deleted') as string
          this.modalAction = () => this.jumpBack()
          this.$bvModal.show('modal-success-file-detail')
        }
      })
      .catch(err => {
        this.catchFileNotFoundErr(err)
      })

      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API move group file to bin
   */
  moveGroupFileToBin() {
    this.$blockui.show()
    GroupSharedFolderService.deleteFileOrMoveFileToBin({
      group_id: this.$route.params.groupId,
      file_ids: [this.currentFile.id],
      delete_type: EDeleteFileType.MOVE_TO_BIN
    })
      .then(res => {
        if (res.status === 200) {
          this.modalMess = this.$t('common.message.deleted') as string
          this.modalAction = () => this.jumpBack()
          this.$bvModal.show('modal-success-file-detail')
        }
      })
      .catch(err => {
        this.catchFileNotFoundErr(err)
      })
      .finally(() => this.$blockui.hide())
  }

  revertFile(historyId: number) {
    this.selectedHistoryId = historyId
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonSharedFolderService.revertCommonFileHistory({
        file_id: this.$route.params.fileId,
        file_history_id: historyId
      })
        .then(res => {
          if (res.status === 200) {
            this.init()
            this.$emit('getFileDetail')
          }
        })
        .catch(err => {
          this.hanldeHistoryActionErr(err)
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupSharedFolderService.revertFileHistory({
        group_id: this.$route.params.groupId,
        file_id: this.$route.params.fileId,
        file_history_id: historyId
      })
        .then(res => {
          if (res.status === 200) {
            this.init()
            this.$emit('getFileDetail')
          }
        })
        .catch(err => {
          this.hanldeHistoryActionErr(err)
        })
        .finally(() => this.$blockui.hide())
    }
  }

  confirmDeleteHistory(historyId: number) {
    this.selectedHistoryId = historyId
    this.$bvModal.show('modal-confirm-delete-history')
  }

  deleteHistory() {
    this.$blockui.show()
    if (this.isCommonTopic) {
      CommonSharedFolderService.deleteCommonHistory(
        this.selectedHistoryId,
        this.$route.params.fileId
      )
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.deleted') as string
            this.modalAction = () => {}
            this.$bvModal.show('modal-success-file-detail')
            this.init()
          }
        })
        .catch(err => {
          this.hanldeHistoryActionErr(err)
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupSharedFolderService.deleteHistory(
        this.$route.params.groupId,
        this.selectedHistoryId,
        this.$route.params.fileId
      )
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.deleted') as string
            this.modalAction = () => {}
            this.$bvModal.show('modal-success-file-detail')
            this.init()
          }
        })
        .catch(err => {
          this.hanldeHistoryActionErr(err)
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * handle history action error
   */
  hanldeHistoryActionErr(res: any) {
    const topicNotFound = this.$t('common.message.topic_not_exist') as string
    const fileNotFound = this.$t('common.message.file_not_exist') as string
    const fileHisNotFound = this.$t(
      'common.message.file_history_not_exist'
    ) as string
    if (res.response.status === 404) {
      if (res.response.data.message === 'topic_not_found') {
        this.modalMess = topicNotFound
        this.fileHistories = this.fileHistories.filter(
          item => item.id !== this.selectedHistoryId
        )
        this.modalAction = () => this.jumpBack()
      } else if (res.response.data.message === 'file_not_found') {
        this.modalMess = fileNotFound
        this.modalAction = () => this.jumpBack()
      } else if (res.response.data.message === 'file_history_not_found') {
        this.modalMess = fileHisNotFound
        this.fileHistories = this.fileHistories.filter(
          item => item.id !== this.selectedHistoryId
        )
        this.modalAction = () => {}
      }
    } else {
      this.modalMess = this.$t('common.message.uncatch_error') as string
      this.modalAction = () => {}
    }
    this.$bvModal.show('modal-error-file-detail')
  }

  /**
   * Jump back
   */
  jumpBack() {
    let router: any = { name: 'home' }
    if (this.$route.params.from) {
      router = this.$route.params.from
    } else {
      if (!this.isCommonTopic) {
        if (this.$route.params.from) {
          router = this.$route.params.from
        } else {
          router = {
            name: 'group-shared-folder',
            query: {
              folder: this.$route.params.folder
                ? this.$route.params.folder
                : this.currentFile.is_in_bin
                ? 'bin'
                : 'all'
            }
          }
        }
      } else {
        router = { name: 'common-topic-shared-folder' }
      }
    }

    this.$router.push(router)
  }

    /**
   * Jump back
   */
  routeBack() {
    let router: any = { name: 'home' }
    if (this.$route.params.from) {
      router = this.$route.params.from
    } else {
      if (!this.isCommonTopic) {
        if (this.$route.params.from) {
          router = this.$route.params.from
        } else {
          router = {
            name: 'group-shared-folder',
            query: {
              folder: this.$route.params.folder
                ? this.$route.params.folder
                : this.currentFile.is_in_bin
                ? 'bin'
                : 'all'
            }
          }
        }
      } else {
        router = { name: 'common-topic-shared-folder' }
      }
    }

    return router
  }

  jumpToFileOverwrite() {
    if (this.isCommonTopic) {
      return { name: 'common-topic-shared-folder-file-overwrite' }
    }
    return { name: 'group-shared-folder-file-overwrite' }
  }

  jumpToFileUpdateInformation() {
    if (this.isCommonTopic) {
      return ({
        name: 'common-topic-shared-folder-file-update-information'
      })
    } else {
      return ({ name: 'group-shared-folder-file-update-information' })
    }
  }

  formatHistoryUpdateDate(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  formatDateWithoutDays(date: string) {
    return FormatDate.formatDate(date)
  }

  handleLongText(createrOrUpdater: any) {
    // var name_custom = ''
    // if (createrOrUpdater.name_company != '') {
    //   name_custom = createrOrUpdater.updaterName + '@' + createrOrUpdater.name_company
    // } else {
    //   name_custom = createrOrUpdater.updaterName
    // }
    let text = this.hanldeUserStatus(
      createrOrUpdater.updaterName,
      createrOrUpdater.name_company,
      createrOrUpdater.user_active_status
    )
    const preview = PreviewText.covertToPreviewText(text)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(name: string, nameCompany:string, status: EUserStatus) {
    var name_custom = ''
    if (nameCompany != '') {
      name_custom = '@' + nameCompany
    } else {
      name_custom = ''
    }
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + name_custom + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name + name_custom
  }
}
